<template>
  <landing-central-card>
    <v-card class="new-password-card flexcard" :elevation="elevation" :min-height="cardHeight" :style="cardStyles" flat>
      <v-card-loader v-if="loading"></v-card-loader>

      <v-card-text :class="this.$vuetify.breakpoint.name === 'xs'? 'pa-0':''">
        <v-form
            @keydown.enter.native="handleEnterKey"
            autocomplete="off"
            transition="slide-y-reverse-transition"
            ref="form"
            v-model="valid">
          <v-layout row wrap justify-center align-center>
            <v-flex xs12 sm11>
              <h1 class="starling-h1 mb-3">{{ $t('public.new_password.title') }}</h1>

                <p class="starling-body" v-html="$t('public.new_password.content.default')"></p>

            </v-flex>

            <v-flex xs12 sm11>
              <h4 class="text-left starling-body mt-4 font-weight-medium">
                {{ $t('public.new_password.fields.password.title') }}
              </h4>
              <registration-field-password class="mt-2"
                                           v-model="newPassword"
                                           :confirmation="newPasswordConfirmation"
                                           @form-validate="onFormValidate"/>
            </v-flex>
            <v-flex xs12 sm11>
              <registration-field-password-confirmation class="mt-2"
                                                        v-model="newPasswordConfirmation"
                                                        :password="newPassword"
                                                        @form-validate="onFormValidate"/>
            </v-flex>
          </v-layout>
          <v-layout v-if="error" row wrap justify-center align-center>
            <v-flex class="mt-1 text-center" xs12 sm11 md10>
              <div class="error--text">{{ error }}</div>
            </v-flex>
          </v-layout>
        </v-form>

      </v-card-text>

      <v-card-actions justify-center align-center>
        <v-layout row wrap justify-center align-center>
          <v-flex xs12 class="text-center">
            <primary-button
                id="btn-do-reset-password"
                :disabled="!valid || loading"
                @click="executePasswordSet"
                class="mt-3"
                large>
              {{ $t('public.new_password.buttons.set_password') }}
            </primary-button>
          </v-flex>
        </v-layout>
      </v-card-actions>

      <v-card-actions justify-center align-center>
        <v-layout row wrap justify-center align-center>
          <v-flex xs12 class="text-center"
                  justify-center align-center text-center>
            <v-btn
                id="btn-cancel"
                :disabled="loading"
                @click="cancel"
                flat>
              {{ $t('common.actions.cancel') }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-actions>
    </v-card>
  </landing-central-card>
</template>

<script>
import vCardLoader from '@/views/components/loader/v-card-loader.vue';
import LandingCentralCard from '@/views/landing/landing-central-card.vue';
import RegistrationFieldPassword from '@/views/landing/register/components/registration-field-password.vue';
import PrimaryButton from '@/views/components/button/primary-button.vue';
import RegistrationFieldPasswordConfirmation
  from '@/views/landing/register/components/registration-field-password-confirmation.vue';
import { mapState } from 'vuex';
import { Auth } from 'aws-amplify';

export default {
  components: {
    RegistrationFieldPassword,
    RegistrationFieldPasswordConfirmation,
    PrimaryButton,
    LandingCentralCard,
    vCardLoader,
  },
  props: [ 'elevation', 'cardHeight', 'cardStyles' ],
  data() {
    return {
      loading: false,
      valid: false,
      newPassword: null,
      newPasswordConfirmation: null,
      error: null,
    };
  },
  computed: {
    ...mapState({
      partner: state => {
        return state.identity;
      },
      locale() {
        return localStorage.getItem('currLang');
      },
    }),
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('identity/emptyCognitoUser');
    next();
  },
  // mounted() {
  //   this.$log.debug(this.partner);
  //   if (!this.partner.cognitoUser || this.partner.cognitoUser.challengeName !== ) {
  //     this.$router.replace({ name: 'sign_in' });
  //   }
  // },
  methods: {
    executePasswordSet() {
      if (!this.partner || !this.partner.cognitoUser || this.partner.cognitoUser.challengeName !== 'NEW_PASSWORD_REQUIRED') {
        this.error = this.$t('public.new_password.error.message');
        return;
      }
      this.loading = true;
      Auth.completeNewPassword(this.partner.cognitoUser, this.newPassword)
        .then(() => {
          return this.$store.dispatch('identity/login', {
            username: this.partner.cognitoUser.username,
            password: this.newPassword,
          }).then(loginResult => {
            this.$log.debug('Login result', loginResult);
            if (!loginResult || !loginResult.userIdentity || !loginResult.userIdentity.location || !loginResult.userIdentity.location['@type']) {
              throw new Error('Cannot determine user');
            }
            if (loginResult.userIdentity.location['@type'] === 'nowhere') {
              return this.$store.dispatch('identity/checkReferral', { email: loginResult.userIdentity.username }).then(refIdentity => {
                if (refIdentity) {
                  return this.$router.replace({
                    name: 'referral_register',
                    query: { referral_key: refIdentity.referralKey },
                  });
                }
                return this.$router.replace({ name: 'setup_access_code' });
              });
            } else if (loginResult.userIdentity.location['@type'] === 'database') {
              if (!loginResult.redirect) {
                return this.$router.replace({ name: 'home' });
              }
            }
          });
        })
        .then(() => {
          this.partner.cognitoUser = null;
        })
        .catch(() => {
          this.error = this.$t('public.new_password.error.message');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleEnterKey() {
      if (this.valid) {
        this.executePasswordSet();
      }
    },
    onFormValidate(fieldname) {
      this.error = false;
      if (this.$refs.form) {
        if (fieldname) {
          const field = this.$refs.form.$data.inputs.find(i => i.$attrs.name === fieldname);
          if (field) {
            field.validate(true);
          }
        } else {
          this.$refs.form.validate();
        }
      }
    },
    cancel() {
      this.$router.push({ name: 'presentation' });
    },
  },
};
</script>

<style scoped>
.new-password-card {
  border-radius: 15px;
}
</style>
